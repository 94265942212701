<template>
  <v-app id="roster_logs">
    <!-- roster names -->
    <v-container id="rname_container">
      <v-row no-gutters>
        <v-col cols="12" md="12" class="rname_expansion">
          <!-- Expansion Panels -->
          <v-expansion-panels>
            <!-- names Expansion Panel -->
            <v-expansion-panel class="mb-3" id="names">
              <v-expansion-panel-header
                class="rname_expansion white--text rounded-b-0"
              >
                ROSTER NAMES
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Roster Names</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rname"
                    >New Name</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- NAMES LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rname">
                        <hr />
                        <v-list-item
                          class="single-name d-flex flex-row justify-space-between"
                          v-for="(item_rname, index) in items_rname"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rname.rstr_name
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="pa-3"
                              @click="editItem_rname(item_rname)"
                              >$edit</v-icon
                            >
                            <v-icon
                              class="pa-3"
                              @click="deleteItem_rname(item_rname)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END names Expansion Panel -->

            <!-- Participants expansion panel -->
            <v-expansion-panel class="mb-3" id="participants">
              <v-expansion-panel-header
                class="part_expansion white--text rounded-b-0"
              >
                COORDINATING/PARTICIPATING AGENCIES OR OFFICERS
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Participating Agencies</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rpart"
                    >New Participant</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- Particpants LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rpart">
                        <hr />
                        <v-list-item
                          class="single-part d-flex flex-row justify-space-between"
                          v-for="(item_rpart, index) in items_rpart"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rpart.part_name
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="pa-3"
                              @click="editItem_rpart(item_rpart)"
                              >$edit</v-icon
                            >
                            <v-icon
                              class="pa-3"
                              @click="deleteItem_rpart(item_rpart)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END participants expansion panel -->

            <!-- Assignments expansion panel -->
            <v-expansion-panel class="mb-3" id="assignments">
              <v-expansion-panel-header
                class="rassignment_expansion white--text rounded-b-0"
              >
                ROSTER ASSIGNMENTS
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Roster Assignments</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rassignment"
                    >New Assignment</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- AssignmentS LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rassignment">
                        <hr />
                        <v-list-item
                          class="single-assignment d-flex flex-row justify-space-between"
                          v-for="(item_rassignment, index) in items_rassignment"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rassignment.assgn_name
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="pa-3"
                              @click="editItem_rassignment(index)"
                              >$edit</v-icon
                            >
                            <v-icon
                              class="pa-3"
                              @click="deleteItem_rassignment(item_rassignment)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END Assignments expansion panel -->

            <!-- Teams Expansion panel -->
            <v-expansion-panel class="mb-3" id="teams">
              <v-expansion-panel-header
                class="rteam_expansion white--text rounded-b-0"
              >
                ROSTER TEAMS
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Roster Teams</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rteam"
                    >New team</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- teamS LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rteam">
                        <hr />
                        <v-list-item
                          class="single-team d-flex flex-row justify-space-between"
                          v-for="(item_rteam, index) in items_rteam"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rteam.team_name
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon class="pa-3" @click="editItem_rteam(index)"
                              >$edit</v-icon
                            >
                            <v-icon
                              class="pa-3"
                              @click="deleteItem_rteam(item_rteam)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END Teams Expansion panel -->

            <!-- Specialties Expansion panel -->
            <v-expansion-panel class="mb-3" id="specialty">
              <v-expansion-panel-header
                class="rspecialty_expansion white--text rounded-b-0"
              >
                SPECIALTIES
                <template v-slot:actions>
                  <v-icon color="white" x-large> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="cmpbkinside">
                <!-- toolbar -->
                <v-toolbar flat class="roster-toolbar">
                  <v-toolbar-title>Specialties</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="openDialog_rspecialty"
                    >New Specialty</v-btn
                  >
                </v-toolbar>
                <!-- <hr /> -->

                <!-- Specialty LIST -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list class="list-rspecialty">
                        <hr />
                        <v-list-item
                          class="single-specialty d-flex flex-row justify-space-between"
                          v-for="(item_rspecialty, index) in items_rspecialty"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item_rspecialty.spec_name
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="d-flex flex-row">
                            <v-icon
                              class="pa-3"
                              @click="editItem_rspecialty(index)"
                              >$edit</v-icon
                            >
                            <v-icon
                              class="pa-3"
                              @click="deleteItem_rspecialty(item_rspecialty)"
                              >$del</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- END Specialties Expansion panel -->
          </v-expansion-panels>
          <!-- end Roster Expansion Panels -->
        </v-col>
      </v-row>

      <!-- New Name Dialog -->

      <v-dialog v-model="openDialog_rname_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rname }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rname.rstr_name"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rnameCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rname"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Name Dialog -->

      <!-- New Participant Dialog -->

      <v-dialog v-model="openDialog_rpart_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rpart }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rpart.part_name"
                    label="Participant"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rpartCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rpart"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Participant Dialog -->

      <!-- New Assignment Dialog -->

      <v-dialog v-model="openDialog_rassignment_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rassignment }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rassignment.assgn_name"
                    label="Assignment"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rassignmentCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="save_assgn_name"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Assignment Dialog -->

      <!-- New Team Dialog -->
      <v-dialog v-model="openDialog_rteam_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rteam }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rteam.team_name"
                    label="team"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rteamCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rteam"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Team Dialog -->

      <!-- New Specialty Dialog -->
      <v-dialog v-model="openDialog_rspecialty_show" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle_rspecialty }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem_rspecialty.spec_name"
                    label="Specialty"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rspecialtyCloseDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem_rspecialty"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END New Specialty Dialog -->
    </v-container>
  </v-app>
</template>

<style lang="scss" scoped>
/* 0 media q */
@media (min-width: 0px) {
  #roster_logs {
    width: 96%;
    margin: 0 auto;
    margin-top: 67px;
  }

  #roster_logs:deep.theme--light.v-application {
    background: none;
  }

  #roster_logs:deep .v-application--wrap {
    /* min-height: 100vh !important; */
    min-height: auto;
  }

  /* roster logs names */
  .rname_expansion {
    height: 48px;
    width: 100%;
    margin: 0 auto;
    background-color: #708e92;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .rname_expansion p {
    font-size: 0.9375rem;
  }

  .roster-toolbar {
    height: 30px !important;
  }

  .single-name {
    border-bottom: 2px solid #0000001f;
  }

  .list-rname hr {
    margin-top: 20px;
    border-color: #0000001f;
  }

  /* End roster names */

  /* Roster Assignments */
  .rassignment_expansion,
  .part_expansion {
    height: 48px;
    width: 100%;
    margin: 0 auto;
    background-color: #708e92;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .rassignment_expansion p,
  .part_expansion p {
    font-size: 0.9375rem;
  }

  .single-assignment,
  .single-part {
    border-bottom: 2px solid #0000001f;
  }

  .list-rassignment hr,
  .list-rpart hr {
    margin-top: 20px;
    border-color: #0000001f;
  }

  /* End Roster assignments */

  /* Roster Teams */
  .rteam_expansion,
  .rspecialty_expansion {
    height: 48px;
    width: 100%;
    margin: 0 auto;
    background-color: #708e92;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .rteam_expansion p,
  .rspecialty_expansion p {
    font-size: 0.9375rem;
  }

  .single-team,
  .single-specialty {
    border-bottom: 2px solid #0000001f;
  }

  .list-rteam hr,
  .list-rspecialty hr {
    margin-top: 20px;
    border-color: #0000001f;
  }

  /* ENDRoster Teams */
}

/* 768 media q */
@media (min-width: 768px) {
}

/* 1025 media q */
@media (min-width: 1025px) {
}
</style>

<script>
import { bus_common } from "../mixins/bus_common";

export default {
  name: "roster_logs",
  components: {},
  mixins: [bus_common],
  data() {
    return {
      openDialog_rname_show: false,
      openDialog_rpart_show: false,
      openDialog_rassignment_show: false,
      openDialog_rteam_show: false,
      openDialog_rspecialty_show: false,
      // items_rname: [
      //   { name: "Autry" },
      //   { name: "Baehr" },
      //   { name: "Butler" },
      //   { name: "Chang" },
      //   { name: "Christensen, B" },
      //   { name: "Clifford" },
      //   { name: "Donigan" },
      //   { name: "Dougherty" },
      //   { name: "Duralde" },
      //   { name: "Espinoza, A" },
      //   { name: "Hakin" },
      //   { name: "Hanna" },
      //   { name: "Hendrix" },
      //   { name: "Hogan" },
      //   { name: "Hoyt" },
      //   { name: "Kent" },
      //   { name: "Kerfoot" },
      //   { name: "Lancaster" },
      //   { name: "Lopey" },
      //   { name: "Mathiowetz" },
      //   { name: "Meseberg" },
      //   { name: "Perham, J" },
      //   { name: "Robles" },
      //   { name: "Rousseau" },
      //   { name: "Schaur" },
      //   { name: "Schmidt" },
      //   { name: "Smith" },
      //   { name: "Sotelo" },
      //   { name: "Struffert" },
      //   { name: "Tom" },
      //   { name: "Tone" },
      //   { name: "Waasdorp" },
      //   { name: "Williams" },
      //   { name: "Ziese" },
      // ],
      items_rname: [],
      // items_rpart: [
      //   { part_name: "SPD" },
      //   { part_name: "WCSO" },
      //   { part_name: "Airport PD" },
      //   { part_name: "School PD" },
      //   { part_name: "Lyon County SO" },
      //   { part_name: "Carson City SO" },
      //   { part_name: "Douglas County SO" },
      //   { part_name: "Secret Service" },
      //   { part_name: "CINT" },
      // ],
      items_rpart: [],
      items_rassignment: [],
      // items_rassignment: [
      //   { assgn_name: "TAC1" },
      //   { assgn_name: "TAC2" },
      //   { assgn_name: "TAC3" },
      //   { assgn_name: "ODC" },
      //   { assgn_name: "C2" },
      //   { assgn_name: "Sick" },
      // ],
      items_rteam: [],
      // items_rteam: [
      //   { team: "RED CELL" },
      //   { team: "GREEN CELL" },
      //   { team: "BLUE CELL" },
      //   { team: "REMSA TEMS" },
      //   { team: "NONE" },
      // ],
      items_rspecialty: [],
      // items_rspecialty: [
      //   { spec_name: "Explosive Breaching AARs" },
      //   { spec_name: "Sniper AARs" },
      //   { spec_name: "Ropes AArmRs" },
      //   { spec_name: "Team training AARs" },
      //   { spec_name: "Mission AARs" },
      // ],

      editedIndex_rname: -1,
      editedItem_rname: {
        roster_nameID: 0,
        rstr_name: "",
      },
      defaultItem_rname: {
        name: "",
      },

      editedIndex_rpart: -1,
      editedItem_rpart: {
        participantId: 0,
        part_name: "",
      },
      defaultItem_rpart: {
        part_name: "",
      },

      editedIndex_rassignment: -1,
      editedItem_rassignment: {
        assignmentId: 0,
        assgn_name: "",
      },
      defaultItem_rassignment: {
        assignmentId: 0,
        assgn_name: "",
      },
      editedIndex_rteam: -1,
      editedItem_rteam: {
        teamId: 0,
        team_name: "",
      },
      defaultItem_rteam: {
        teamId: 0,
        team_name: "",
      },
      editedIndex_rspecialty: -1,
      editedItem_rspecialty: {
        spec_name: "",
      },
      defaultItem_rspecialty: {
        specialtyId: 0,
        spec_name: "",
      },
    };
  },
  computed: {
    admin() {
          //return this.$store.state.user.roles.indexOf("admin") > -1;
          return true;
    },
    formTitle_rname() {
      return this.editedIndex_rname === -1
        ? "New Roster Name"
        : "Edit Roster Name";
    },
    formTitle_rpart() {
      return this.editedIndex_rpart === -1
        ? "New Participant Name"
        : "Edit Participant Name";
    },
    formTitle_rassignment() {
      return this.editedIndex_rassignment === -1
        ? "New Roster assignment"
        : "Edit Roster assignment";
    },
    formTitle_rteam() {
      return this.editedIndex_rteam === -1
        ? "New Roster team"
        : "Edit Roster team";
    },
    formTitle_rspecialty() {
      return this.editedIndex_rspecialty === -1
        ? "New Specialty"
        : "Edit Specialty";
    },
  },

  /* METHODS */
  methods: {
    //speacilties
    deleteItem_rspecialty(item_rspecialty) {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.specialtyId = item_rspecialty.specialtyId;
      formIn.apiname = "deleteItem_rspecialty";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rspecialty = self.mno(data);
          this.show_loader(false);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Deleting", msg, "error", null, null);
        });
    },
    get_specials() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;

      formIn.apiname = "get_specials";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rspecialty = self.mno(data);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    saveItem_rspecialty() {
      //     this.editedItem_rname
      // console.log("stat_data: " + stat_data.arrest);
      // this.save_stat_data = stat_data;
      console.log("save start");
      // this.mrbreakpoint("save");
      if (
        this.editedItem_rspecialty.spec_name == null ||
        this.editedItem_rspecialty.spec_name == ""
      ) {
        this.swall_it("Save Status", "Enter a Name", "warning", null, null);
        return true;
      }

      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.spec_name = this.editedItem_rspecialty.spec_name;
      formIn.specialtyId = this.editedItem_rspecialty.specialtyId;
      if (!formIn.specialtyId > 0) {
        formIn.specialtyId = 0;
      }

      formIn.apiname = "save_spec_name";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rspecialty = self.mno(data);
          self.rspecialtyCloseDialog();
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    //teams
    saveItem_rteam() {
      //     this.editedItem_rname
      // console.log("stat_data: " + stat_data.arrest);
      // this.save_stat_data = stat_data;
      console.log("save start");
      // this.mrbreakpoint("save");
      if (
        this.editedItem_rteam.team_name == null ||
        this.editedItem_rteam.team_name == ""
      ) {
        this.swall_it("Save Status", "Enter a Name", "warning", null, null);
        return true;
      }

      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.team_name = this.editedItem_rteam.team_name;
      formIn.teamId = this.editedItem_rteam.teamId;
      if (!formIn.teamId > 0) {
        formIn.teamId = 0;
      }

      formIn.apiname = "save_team_name";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rteam = self.mno(data);
          self.rteamCloseDialog();
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    get_team_names() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;

      formIn.apiname = "get_team_names";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rteam = self.mno(data);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    //item_rteam.team_name
    //assignement
    deleteItem_rassignment(item_rassignment) {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.assignmentId = item_rassignment.assignmentId;
      formIn.apiname = "deleteItem_rassignment";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rassignment = self.mno(data);
          this.show_loader(false);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Deleting", msg, "error", null, null);
        });
    },
    save_assgn_name() {
      //     this.editedItem_rname
      // console.log("stat_data: " + stat_data.arrest);
      // this.save_stat_data = stat_data;
      console.log("save start");
      // this.mrbreakpoint("save");
      if (
        this.editedItem_rassignment.assgn_name == null ||
        this.editedItem_rassignment.assgn_name == ""
      ) {
        this.swall_it("Save Status", "Enter a Name", "warning", null, null);
        return true;
      }

      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.assgn_name = this.editedItem_rassignment.assgn_name;
      formIn.assignmentId = this.editedItem_rassignment.assignmentId;
      if (!formIn.assignmentId > 0) {
        formIn.assignmentId = 0;
      }

      formIn.apiname = "save_assgn_name";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rassignment = self.mno(data);
          self.rassignmentCloseDialog();
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    get_assgn_names() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;

      formIn.apiname = "get_assgn_names";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rassignment = self.mno(data);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    editItem_rassignment(index) {
      this.editedIndex_rassignment = index;
      this.editedItem_rassignment = this.mno(this.items_rassignment[index]);
      this.openDialog_rassignment_show = true;
    },
    //parts name
    deleteItem_rpart(item) {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.participantId = item.participantId;
      formIn.apiname = "deleteItem_rpart";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rpart = self.mno(data);
          this.show_loader(false);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Deleting", msg, "error", null, null);
        });
    },
    get_part_names() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;

      formIn.apiname = "get_part_names";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          console.log("part cnt: " + data.length);
          self.items_rpart = self.mno(data);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    saveItem_rpart() {
      //     this.editedItem_rname
      // console.log("stat_data: " + stat_data.arrest);
      // this.save_stat_data = stat_data;
      console.log("save start");
      // this.mrbreakpoint("save");
      if (
        this.editedItem_rpart.part_name == null ||
        this.editedItem_rpart.part_name == ""
      ) {
        this.swall_it("Save Status", "Enter a Name", "warning", null, null);
        return true;
      }

      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.part_name = this.editedItem_rpart.part_name;
      formIn.participantId = this.editedItem_rpart.participantId;
      if (!formIn.participantId > 0) {
        formIn.participantId = 0;
      }

      formIn.apiname = "save_part_name";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rpart = self.mno(data);
          self.rpartCloseDialog();
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },

    //items_rname

    deleteItem_rteam(item) {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.teamId = item.teamId;
      formIn.apiname = "deleteItem_rteam";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rteam = self.mno(data);
          this.show_loader(false);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Deleting", msg, "error", null, null);
        });
    },
    get_roster_names() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;

      formIn.apiname = "get_roster_names";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          console.log("nm cnt: " + data.length);
          self.items_rname = self.mno(data);
          self.rnameCloseDialog();
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    deleteItem_rname(item) {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.roster_nameID = item.roster_nameID;
      formIn.apiname = "deleteItem_rname";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rname = self.mno(data);
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Deleting", msg, "error", null, null);
        });
    },
    saveItem_rname() {
      //     this.editedItem_rname
      // console.log("stat_data: " + stat_data.arrest);
      // this.save_stat_data = stat_data;
      console.log("save start");
      // this.mrbreakpoint("save");
      if (
        this.editedItem_rname.rstr_name == null ||
        this.editedItem_rname.rstr_name == ""
      ) {
        this.swall_it("Save Status", "Enter a Name", "warning", null, null);
        return true;
      }

      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.rname = this.editedItem_rname.rstr_name;
      formIn.roster_nameID = this.editedItem_rname.roster_nameID;
      if (!formIn.roster_nameID > 0) {
        formIn.roster_nameID = 0;
      }

      formIn.apiname = "saveItem_rname";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.items_rname = self.mno(data);
          self.rnameCloseDialog();
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saving", msg, "error", null, null);
        });
    },
    /* Roster names */
    openDialog_rname() {
      if (this.admin == false) {
        this.swall_it(
          "Sorry",
          "You msut be an Admin to do this.",
          "warning",
          null,
          null
        );
        return true;
      }
      this.openDialog_rname_show = true;
      this.editedItem_rname = this.mno(this.defaultItem_rname);
    },
    editItem_rname(item) {
      this.editedItem_rname = this.mno(item);
      this.openDialog_rname_show = true;
    },

    rnameCloseDialog() {
      this.openDialog_rname_show = false;
      this.$nextTick(() => {
        this.editedItem_rname = this.mno(this.defaultItem_rname);
        this.editedIndex_rname = -1;
      });
    },
    // saveItem_rname() {
    //   console.log(this.editedItem_rname.name);
    //   if (this.editedIndex_rname > -1) {
    //     this.items_rname[this.editedIndex_rname] = this.mno(
    //       this.editedItem_rname
    //     );
    //   } else {
    //     this.items_rname.push(this.editedItem_rname);
    //   }
    //   this.rnameCloseDialog();
    // },

    /* Roster Assignment */
    openDialog_rassignment() {
      this.openDialog_rassignment_show = true;
      this.editedItem_rassignment = this.mno(this.defaultItem_rassignment);
    },

    rassignmentCloseDialog() {
      this.openDialog_rassignment_show = false;
      this.$nextTick(() => {
        this.editedItem_rassignment = this.mno(this.defaultItem_rassignment);
        this.editedIndex_rassignment = -1;
      });
    },

    // END roster assignments

    // Participant Assignment
    openDialog_rpart() {
      this.openDialog_rpart_show = true;
      this.editedItem_rpart = this.mno(this.defaultItem_rpart);
    },
    editItem_rpart(item) {
      this.editedItem_rpart = this.mno(item);
      this.openDialog_rpart_show = true;
    },

    rpartCloseDialog() {
      this.openDialog_rpart_show = false;
      this.$nextTick(() => {
        this.editedItem_rpart = this.mno(this.defaultItem_rpart);
        this.editedIndex_rpart = -1;
      });
    },

    // TEAM DIALOG
    openDialog_rteam() {
      this.openDialog_rteam_show = true;
      this.editedItem_rteam = this.mno(this.defaultItem_rteam);
    },
    editItem_rteam(index) {
      this.editedIndex_rteam = index;
      this.editedItem_rteam = this.mno(this.items_rteam[index]);
      this.openDialog_rteam_show = true;
    },

    rteamCloseDialog() {
      this.openDialog_rteam_show = false;
      this.$nextTick(() => {
        this.editedItem_rteam = this.mno(this.defaultItem_rteam);
        this.editedIndex_rteam = -1;
      });
    },

    // SPECIALTY DIALOG
    openDialog_rspecialty() {
      this.openDialog_rspecialty_show = true;
      this.editedItem_rspecialty = this.mno(this.defaultItem_rspecialty);
    },
    editItem_rspecialty(index) {
      this.editedIndex_rspecialty = index;
      this.editedItem_rspecialty = this.mno(this.items_rspecialty[index]);
      this.openDialog_rspecialty_show = true;
    },

    rspecialtyCloseDialog() {
      this.openDialog_rspecialty_show = false;
      this.$nextTick(() => {
        this.editedItem_rspecialty = this.mno(this.defaultItem_rspecialty);
        this.editedIndex_rspecialty = -1;
      });
    },

    init() {
      this.get_roster_names();
      this.get_part_names();
      this.get_assgn_names();
      this.get_team_names();
      this.get_specials();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let lstlinks = document.querySelectorAll(".menulink");
      console.log("roster lengths: " + lstlinks.length);
      for (let index = 0; index < lstlinks.length; index++) {
        const element = lstlinks[index];
        element.style.color = "white";
      }
      document.getElementById("rosterslink").style.color = "orange";
      this.init();
    });
  },
};
</script>
